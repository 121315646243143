






















import { Component, Emit, Vue, Prop, Watch } from 'vue-property-decorator';
import { WecomVideoServices } from '@/services/wecom-video-service';
import { Inject } from '@cds/common';
import { map, join, find, forEach, isEmpty, get } from 'lodash';

@Component({})
export default class UserGroupSelector extends Vue {
    [x: string]: any;
    public groupTitle: String = '选择用户组';
    public groupVisible: boolean = false;
    public groupDataList: any[] = [];
    public groupValue: Array<any> = [];
    public groupStringNames: string = ''
    public disabledTosend: Boolean = false;
    public groupQueryParams: any = { //查询参
        userGroupName: '',
        pageNo: 1,
        pageSize: 100000,
        total: 0
    };
    @Prop({ type: Array, default: [] }) value !: any[]
    @Prop({ type: Boolean, default: false }) disabled !: Boolean
    @Watch('value')
    private setValue(newVal: any[]) {
        this.groupValue = newVal;
        if (isEmpty(this.groupDataList)) return
        this.groupStringNames = join(map(newVal, (value) => {
                return get(find(this.groupDataList, { key: value }), 'label', value)
            }), ',')
    }
    @Inject(WecomVideoServices) private wecomVideoServices!: WecomVideoServices;
    public async created(){
        await this.getGroupList();
        if (this.$props.value) {
            this.groupValue = [...this.$props.value];
            this.groupStringNames = join(map(this.groupValue, (value) => {
                return get(find(this.groupDataList, { key: value }), 'label', value)
            }), ',')
        }
    }
    public goUserGroup() {
      const origin = window.location.origin;
      window.open(`${origin}/usergroup`)
    }
    public groupFilterMethod(query: any, item: any) {
        return item.label.indexOf(query) > -1;
    }
    public async refresh () {
      const  loading = this.$loading({
        text: '刷新中',
      })
      await this.getGroupList()
      loading.close();
    }
    @Emit('input')
    public onChange(value) {
      return value
    }

    @Emit('submit')
    public groupSubmit() {
        this.groupVisible = false;
        this.groupStringNames = join(map(this.groupValue, (value) => {
            return get(find(this.groupDataList, { key: value }), 'label', value)
        }), ',')
        const groupIds: any[] = [];
        const customGroupIds: any[] = [];
    
        forEach(this.groupValue, (val) => {
          const target = find(this.groupDataList, { key: val });
          if (target.isCustomer) {
            customGroupIds.push(val)
          } else {
            groupIds.push(val);
          }
        })
        this.onChange(this.groupValue);
        return {
          groupIds,
          customGroupIds
        }
    }
  
    public groupChoose() {
        if (this.$props.disabled) return;
        this.groupVisible = true
    }
    public async getGroupList(): Promise<void> {

        const res = await this.wecomVideoServices.getAllUserGroups(this.groupQueryParams);
        this.groupDataList = res.records.map((item: any, index: any) => {
            return {
                ...item,
                key: item.userGroupId,
                value: item.userGroupId,
                label: `${item.userGroupName}${item.isCustomer ? '(自定义分组）' : ''}`,
                userGroupingId: item.userGroupId
            };
        });
        this.groupStringNames = join(map(this.groupValue, (value) => {
            return find(this.groupDataList, { key: value })
        }), ',')
        this.groupQueryParams.total = res.total;
    }
}
